.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-color: white !important; 
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    color: black;
    font-weight: 500;
    font-size: 20px;
    border-top-left-radius: 0.625rem !important;
    border-top-right-radius: 0.625rem !important;
}
