:root {
  --white: #fff;
  --black: #000;
  --n100: #f8f9fa;
  --n200: #e1e7ec;
  --n300: #cfd6de;
  --n400: #b8c4ce;
  --n500: #8895a7;
  --n600: #5f6b7a;
  --n700: #515760;
  --n800: #39404a;
  --n900: #212934;
  --b100: #eff8ff;
  --b200: #aad4f5;
  --b300: #63a2d8;
  --b400: #3183c8;
  --b500: #2368a2;
  --b600: #1f598a;
  --b700: #1a4971;
  --b800: #1d4363;
  --b900: #203d54;
  --c100: #e7fffe;
  --c200: #a8eeeb;
  --c300: #6ed7d3;
  --c400: #55c3bb;
  --c500: #3caea3;
  --c600: #33a095;
  --c700: #2a9187;
  --c800: #1b655e;
  --c900: #114443;
  --g100: #e3fcec;
  --g200: #a8eec1;
  --g300: #74d99f;
  --g400: #56cd89;
  --g500: #38c172;
  --g600: #2eaf65;
  --g700: #249d57;
  --g800: #187741;
  --g900: #155239;
  --v100: #f0f4fe;
  --v200: #d4def8;
  --v300: #95aeed;
  --v400: #758ce0;
  --v500: #6175de;
  --v600: #495dc6;
  --v700: #3547a4;
  --v800: #253586;
  --v900: #1f2c6d;
  --o100: #efd0bb;
  --o200: #f7b97c;
  --o300: #f7941d;
  --o400: #e38014;
  --o500: #ce6c0b;
  --o600: #ae5907;
  --o700: #8e4503;
  --o800: #7a3f0f;
  --o900: #66391b;
  --y100: #fffcf4;
  --y200: #fdf3d7;
  --y300: #fae29f;
  --y400: #f4ca64;
  --y500: #caa53d;
  --y600: #ab892e;
  --y700: #8c6d1f;
  --y800: #745b19;
  --y900: #5c4813;
  --r100: #fce8e8;
  --r200: #f4aaaa;
  --r300: #e36363;
  --r400: #dc3030;
  --r500: #b82020;
  --r600: #a01d1e;
  --r700: #881a1b;
  --r800: #741919;
  --r900: #5f1717;
  --p100: var(--b100);
  --p200: var(--b200);
  --p300: var(--b300);
  --p400: var(--b400);
  --p500: var(--b500);
  --p600: var(--b600);
  --p700: var(--b700);
  --p800: var(--b800);
  --p900: var(--b900);
  --shadow: rgba(12, 15, 20, 0.07);
  --overlay: rgba(12, 15, 20, 0.21);
  --error: var(--r500);
  --error-light: var(--r100);
  --success: var(--g700);
  --success-light: var(--g100);
  --warning: var(--y500);
  --warning-light: var(--y200);
  --font-family-regular: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  --font-family-mono: "SFMono-Regular", "Menlo", "Monaco", "Consolas",
    "Liberation Mono", "Courier New", monospace;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-bold: 700;
  --font-weight-black: 900;
  --font-size-xs: 10px;
  --font-size-sm: 13px;
  --font-size-md: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 24px;
  --font-size-xxl: 36px;
  --font-size-3xl: 48px;
  --font-size-4xl: 64px;
  --transition-default: 120ms ease-in-out;
  --transition-slow: 300ms ease-in-out;
  --border-radius-xs: 1px;
  --border-radius-sm: 2px;
  --border-radius-md: 4px;
  --border-radius-lg: 6px;
  --border-radius-xl: 8px;
  --border-radius-circle: 100%;
  --border-radius-pill: 99999px;
  --border-wdith-md: 1px;
  --border-wdith-lg: 2px;
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 12px;
  --spacing-lg: 16px;
  --spacing-xl: 24px;
  --spacing-xxl: 32px;
  --spacing-3xl: 40px;
  --spacing-4xl: 64px;
  --icon-size-md: 16px;
  --icon-size-lg: 24px;
  --icon-size-xl: 32px;
  --icon-size-xxl: 48px;
  --bg-light: var(--n100);
  --color-light: var(--n900);
  --bg-dark: var(--n900);
  --color-dark: var(--n100);
  --xs-max: 479px;
  --sm-min: 480px;
  --sm-max: 767px;
  --md-min: 768px;
  --md-max: 959px;
  --lg-min: 960px;
  --lg-max: 1279px;
  --xl-min: 1280px;
  --max-width: --xl-min;
  --gutter-width: 1rem;
  --outer-margin: 2rem;
  --gutter-compensation: calc(var(--gutter-width) * 0.5 * -1);
  --half-gutter-width: calc(var(--gutter-width) * 0.5);
  --container-sm: calc(var(--sm-min) + var(--gutter-width));
  --container-md: calc(var(--md-min) + var(--gutter-width));
  --container-lg: calc(var(--lg-min) + var(--gutter-width));
  --container-xl: calc(var(--xl-min) + var(--gutter-width));
}
.u-hide {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.u-textcenter {
  text-align: center;
}
.u-textright {
  text-align: right;
}
.u-textleft {
  text-align: left;
}
.u-uppercase {
  text-transform: uppercase;
}
.u-lowercase {
  text-transform: lowercase;
}
.u-capitalize {
  text-transform: capitalize;
}
.u-rounded {
  border-radius: var(--border-radius-md);
}
.u-shadow {
  box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent,
    0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
main {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
/* * {
  box-sizing: border-box;
} */
/* todo: if this affects something */
html {
  font-family: var(--font-family-regular);
}
body {
  background-color: var(--bg-light);
  color: var(--color-light);
  font-size: 16px;
  line-height: 1.4;
}
body * {
  border-color: var(--n300);
}
body.dark {
  background-color: var(--bg-dark);
  color: var(--color-dark);
}
body.dark * {
  border-color: var(--n600);
}
code,
pre {
  border-radius: var(--border-radius-md);
  font-family: var(--font-family-mono);
  padding: var(--spacing-xs);
}
.fx-accordion-item {
  border-color: inherit;
  border-style: solid;
  border-width: 1px 1px 0;
}
.fx-accordion-item:first-of-type {
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
}
.fx-accordion-item:last-of-type {
  border-bottom-left-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
  border-bottom-width: 1px;
}
.fx-accordion-item-header {
  appearance: none;
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  outline: 0 none currentColor;
  padding: 0.5rem 1rem;
  transition: all var(--transition-default);
  width: 100%;
}
.fx-accordion-item-header-title {
  align-items: center;
  display: flex;
  flex-grow: 1;
  text-align: left;
}
.fx-accordion-item-header:hover {
  background-color: var(--n200);
}
.fx-accordion-item-header:focus {
  box-shadow: 0 0 0 4px var(--p200);
  outline: 0;
}
.fx-accordion-item-panel {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height var(--transition-default),
    opacity var(--transition-default), transform var(--transition-default);
}
.fx-accordion-item-icon {
  justify-self: flex-end;
}
.fx-accordion-item--is-selected .fx-accordion-item-panel {
  height: auto;
  opacity: 1;
  padding: 0.5rem 1rem 1rem;
}
.fx-alert--none.fx-alert--outline {
  border: 1px solid var(--n600);
  color: var(--n600);
}
.fx-alert--none.fx-alert--solid {
  background-color: var(--n900);
  color: #fff;
}
.fx-alert--none.fx-alert--subtle {
  background-color: var(--n200);
  color: var(--n900);
}
.fx-alert--success.fx-alert--outline {
  border: 1px solid var(--success);
  color: var(--success);
}
.fx-alert--success.fx-alert--solid {
  background-color: var(--success);
  color: #fff;
}
.fx-alert--success.fx-alert--subtle {
  background-color: var(--success-light);
  color: var(--success);
}
.fx-alert--error.fx-alert--outline {
  border: 1px solid var(--error);
  color: var(--error);
}
.fx-alert--error.fx-alert--solid {
  background-color: var(--error);
  color: #fff;
}
.fx-alert--error.fx-alert--subtle {
  background-color: var(--error-light);
  color: var(--error);
}
.fx-alert--info.fx-alert--outline {
  border: 1px solid var(--b500);
  color: var(--b500);
}
.fx-alert--info.fx-alert--solid {
  background-color: var(--b500);
  color: #fff;
}
.fx-alert--info.fx-alert--subtle {
  background-color: var(--b200);
  color: var(--b700);
}
.fx-alert--warning.fx-alert--outline {
  border: 1px solid var(--warning);
  color: var(--warning);
}
.fx-alert--warning.fx-alert--solid {
  background-color: var(--warning);
  color: #fff;
}
.fx-alert--warning.fx-alert--subtle {
  background-color: var(--warning-light);
  color: var(--warning);
}
.fx-alert-icon {
  margin-right: var(--spacing-md);
}
.fx-alert-icon svg {
  vertical-align: middle;
}
.fx-alert-description {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.fx-alert-description--is-inline {
  flex-direction: row;
  text-align: inherit;
}
.fx-alert-title {
  font-weight: 600;
  margin-right: var(--spacing-md);
}
.fx-avatar {
  position: relative;
}
.fx-avatar-initials {
  align-items: center;
  background-color: var(--p500);
  color: var(--color-dark);
  display: flex;
  justify-content: center;
}
.fx-avatar--is-circle {
  border-radius: var(--border-radius-pill);
}
.fx-avatar--has-border {
  border: 2px solid var(--bg-light);
}
body.dark .fx-avatar--has-border {
  border-color: var(--bg-dark);
}
.fx-avatar-badge {
  align-items: center;
  background-color: var(--success);
  border: 0.25em solid var(--bg-light);
}
body.dark .fx-avatar-badge {
  border-color: var(--bg-dark);
}
.fx-avatar-badge {
  border-radius: var(--border-radius-pill);
  bottom: 0;
  display: flex;
  height: 40%;
  justify-content: center;
  position: absolute;
  right: 0;
  transform: translate(25%, 25%);
  width: 40%;
}
.fx-avatargroup {
  align-items: center;
  display: flex;
}
.fx-avatargroup .avatar {
  display: inline-flex;
}
.fx-avatargroup .avatar:not(:first-of-type) {
  margin-left: -1rem;
}
.fx-badge {
  border-radius: var(--border-radius-md);
  display: inline-block;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  padding: var(--spacing-xs) var(--spacing-sm);
  vertical-align: middle;
  white-space: nowrap;
}
.fx-badge--none.fx-badge--outline {
  border: 1px solid var(--n600);
  color: var(--n600);
}
.fx-badge--none.fx-badge--solid {
  background-color: var(--n900);
  color: #fff;
}
.fx-badge--none.fx-badge--subtle {
  background-color: var(--n200);
  color: var(--n900);
}
.fx-badge--success.fx-badge--outline {
  border: 1px solid var(--success);
  color: var(--success);
}
.fx-badge--success.fx-badge--solid {
  background-color: var(--success);
  color: #fff;
}
.fx-badge--success.fx-badge--subtle {
  background-color: var(--success-light);
  color: var(--success);
}
.fx-badge--error.fx-badge--outline {
  border: 1px solid var(--error);
  color: var(--error);
}
.fx-badge--error.fx-badge--solid {
  background-color: var(--error);
  color: #fff;
}
.fx-badge--error.fx-badge--subtle {
  background-color: var(--error-light);
  color: var(--error);
}
.fx-badge--info.fx-badge--outline {
  border: 1px solid var(--p500);
  color: var(--p500);
}
.fx-badge--info.fx-badge--solid {
  background-color: var(--p500);
  color: #fff;
}
.fx-badge--info.fx-badge--subtle {
  background-color: var(--p200);
  color: var(--p700);
}
.fx-badge--warning.fx-badge--outline {
  border: 1px solid var(--warning);
  color: var(--warning);
}
.fx-badge--warning.fx-badge--solid {
  background-color: var(--warning);
  color: #fff;
}
.fx-badge--warning.fx-badge--subtle {
  background-color: var(--warning-light);
  color: var(--warning);
}
.fx-badge--is-circle {
  border-radius: var(--border-radius-pill);
}
.fx-box--spacing-p-xs {
  padding: var(--spacing-xs);
}
.fx-box--spacing-p-sm {
  padding: var(--spacing-sm);
}
.fx-box--spacing-p-md {
  padding: var(--spacing-md);
}
.fx-box--spacing-p-lg {
  padding: var(--spacing-lg);
}
.fx-box--spacing-p-xl {
  padding: var(--spacing-xl);
}
.fx-box--spacing-px-xs {
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xs);
}
.fx-box--spacing-px-sm {
  padding-left: var(--spacing-sm);
  padding-right: var(--spacing-sm);
}
.fx-box--spacing-px-md {
  padding-left: var(--spacing-md);
  padding-right: var(--spacing-md);
}
.fx-box--spacing-px-lg {
  padding-left: var(--spacing-lg);
  padding-right: var(--spacing-lg);
}
.fx-box--spacing-px-xl {
  padding-left: var(--spacing-xl);
  padding-right: var(--spacing-xl);
}
.fx-box--spacing-py-xs {
  padding-bottom: var(--spacing-xs);
  padding-top: var(--spacing-xs);
}
.fx-box--spacing-py-sm {
  padding-bottom: var(--spacing-sm);
  padding-top: var(--spacing-sm);
}
.fx-box--spacing-py-md {
  padding-bottom: var(--spacing-md);
  padding-top: var(--spacing-md);
}
.fx-box--spacing-py-lg {
  padding-bottom: var(--spacing-lg);
  padding-top: var(--spacing-lg);
}
.fx-box--spacing-py-xl {
  padding-bottom: var(--spacing-xl);
  padding-top: var(--spacing-xl);
}
.fx-box--spacing-pt-xs {
  padding-top: var(--spacing-xs);
}
.fx-box--spacing-pt-sm {
  padding-top: var(--spacing-sm);
}
.fx-box--spacing-pt-md {
  padding-top: var(--spacing-md);
}
.fx-box--spacing-pt-lg {
  padding-top: var(--spacing-lg);
}
.fx-box--spacing-pt-xl {
  padding-top: var(--spacing-xl);
}
.fx-box--spacing-pr-xs {
  padding-right: var(--spacing-xs);
}
.fx-box--spacing-pr-sm {
  padding-right: var(--spacing-sm);
}
.fx-box--spacing-pr-md {
  padding-right: var(--spacing-md);
}
.fx-box--spacing-pr-lg {
  padding-right: var(--spacing-lg);
}
.fx-box--spacing-pr-xl {
  padding-right: var(--spacing-xl);
}
.fx-box--spacing-pb-xs {
  padding-bottom: var(--spacing-xs);
}
.fx-box--spacing-pb-sm {
  padding-bottom: var(--spacing-sm);
}
.fx-box--spacing-pb-md {
  padding-bottom: var(--spacing-md);
}
.fx-box--spacing-pb-lg {
  padding-bottom: var(--spacing-lg);
}
.fx-box--spacing-pb-xl {
  padding-bottom: var(--spacing-xl);
}
.fx-box--spacing-pl-xs {
  padding-left: var(--spacing-xs);
}
.fx-box--spacing-pl-sm {
  padding-left: var(--spacing-sm);
}
.fx-box--spacing-pl-md {
  padding-left: var(--spacing-md);
}
.fx-box--spacing-pl-lg {
  padding-left: var(--spacing-lg);
}
.fx-box--spacing-pl-xl {
  padding-left: var(--spacing-xl);
}
.fx-box--spacing-m-xs {
  margin: var(--spacing-xs);
}
.fx-box--spacing-m-sm {
  margin: var(--spacing-sm);
}
.fx-box--spacing-m-md {
  margin: var(--spacing-md);
}
.fx-box--spacing-m-lg {
  margin: var(--spacing-lg);
}
.fx-box--spacing-m-xl {
  margin: var(--spacing-xl);
}
.fx-box--spacing-mx-xs {
  margin-left: var(--spacing-xs);
  margin-right: var(--spacing-xs);
}
.fx-box--spacing-mx-sm {
  margin-left: var(--spacing-sm);
  margin-right: var(--spacing-sm);
}
.fx-box--spacing-mx-md {
  margin-left: var(--spacing-md);
  margin-right: var(--spacing-md);
}
.fx-box--spacing-mx-lg {
  margin-left: var(--spacing-lg);
  margin-right: var(--spacing-lg);
}
.fx-box--spacing-mx-xl {
  margin-left: var(--spacing-xl);
  margin-right: var(--spacing-xl);
}
.fx-box--spacing-my-xs {
  margin-bottom: var(--spacing-xs);
  margin-top: var(--spacing-xs);
}
.fx-box--spacing-my-sm {
  margin-bottom: var(--spacing-sm);
  margin-top: var(--spacing-sm);
}
.fx-box--spacing-my-md {
  margin-bottom: var(--spacing-md);
  margin-top: var(--spacing-md);
}
.fx-box--spacing-my-lg {
  margin-bottom: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}
.fx-box--spacing-my-xl {
  margin-bottom: var(--spacing-xl);
  margin-top: var(--spacing-xl);
}
.fx-box--spacing-mt-xs {
  margin-top: var(--spacing-xs);
}
.fx-box--spacing-mt-sm {
  margin-top: var(--spacing-sm);
}
.fx-box--spacing-mt-md {
  margin-top: var(--spacing-md);
}
.fx-box--spacing-mt-lg {
  margin-top: var(--spacing-lg);
}
.fx-box--spacing-mt-xl {
  margin-top: var(--spacing-xl);
}
.fx-box--spacing-mr-xs {
  margin-right: var(--spacing-xs);
}
.fx-box--spacing-mr-sm {
  margin-right: var(--spacing-sm);
}
.fx-box--spacing-mr-md {
  margin-right: var(--spacing-md);
}
.fx-box--spacing-mr-lg {
  margin-right: var(--spacing-lg);
}
.fx-box--spacing-mr-xl {
  margin-right: var(--spacing-xl);
}
.fx-box--spacing-mb-xs {
  margin-bottom: var(--spacing-xs);
}
.fx-box--spacing-mb-sm {
  margin-bottom: var(--spacing-sm);
}
.fx-box--spacing-mb-md {
  margin-bottom: var(--spacing-md);
}
.fx-box--spacing-mb-lg {
  margin-bottom: var(--spacing-lg);
}
.fx-box--spacing-mb-xl {
  margin-bottom: var(--spacing-xl);
}
.fx-box--spacing-ml-xs {
  margin-left: var(--spacing-xs);
}
.fx-box--spacing-ml-sm {
  margin-left: var(--spacing-sm);
}
.fx-box--spacing-ml-md {
  margin-left: var(--spacing-md);
}
.fx-box--spacing-ml-lg {
  margin-left: var(--spacing-lg);
}
.fx-box--spacing-ml-xl {
  margin-left: var(--spacing-xl);
}
.fx-breadcrumb-list {
  margin: 0;
  padding: 0;
}
.fx-breadcrumb-list-item {
  align-items: center;
  display: inline-flex;
}
.fx-breadcrumb-list-item-link {
  color: inherit;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: all var(--transition-defaul);
}
.fx-breadcrumb-list-item-link:hover {
  text-decoration: underline;
}
.fx-button {
  appearance: none;
  align-items: center;
  background: transparent;
  border: 1px solid;
  border-color: inherit;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  height: auto;
  justify-content: center;
  line-height: 1;
  margin: 0;
  padding: var(--spacing-sm) var(--spacing-lg);
  text-align: center;
  text-decoration: none;
  transition: opacity var(--transition-default), color var(--transition-default),
    background-color var(--transition-default),
    border-color var(--transition-default), transform var(--transition-default),
    border-color var(--transition-default);
  width: auto;
}
.fx-button--is-rounded {
  border-radius: var(--border-radius-lg);
}
.fx-button:focus {
  box-shadow: 0 0 0 4px var(--p200);
  outline: 0;
}
.fx-button:disabled,
.fx-button[disabled] {
  box-shadow: 0;
  opacity: 0.5;
  pointer-events: none;
}
.fx-button--solid {
  background-color: var(--p500);
  border-color: transparent;
  color: #fff;
}
.fx-button--solid:hover {
  background-color: var(--p700);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transform: translateY(-2px);
}
.fx-button--solid:active {
  background-color: var(--p900);
}
.fx-button--outline {
  border-color: inherit !important;
  color: inherit;
}
.fx-button--outline:active {
  border-color: var(--n600);
}
.fx-button--outline:hover {
  background-color: var(--n200);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transform: translateY(-2px);
}
body.dark .fx-button--outline:hover {
  background-color: var(--n600);
}
.fx-button--ghost {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
}
.fx-button--ghost:active,
.fx-button--ghost:hover {
  background-color: var(--p200);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transform: translateY(-2px);
}
body.dark .fx-button--ghost:active,
body.dark .fx-button--ghost:hover {
  background-color: var(--n600);
}
.fx-button--link {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
}
.fx-button--link:active,
.fx-button--link:hover {
  text-decoration: underline;
}
.fx-button--is-fullwidth {
  width: 100%;
}
.fx-button .has-icon {
  margin-right: 12px;
}
.fx-button--xs {
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
}
.fx-button--sm {
  border-radius: var(--border-radius-lg);
  font-size: var(--font-size-sm);
  padding: var(--spacing-xs) var(--spacing-md);
}
.fx-button--lg {
  border-radius: var(--border-radius-xl);
  font-size: var(--font-size-lg);
  padding: var(--spacing-md) var(--spacing-xl);
}
.fx-button--has-iconright {
  flex-direction: row-reverse;
}
.fx-button--has-iconright .has-icon {
  margin-left: 12px;
  margin-right: 0;
}
.fx-buttongroup {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  width: 100%;
}
.fx-buttongroup > a,
.fx-buttongroup > button {
  margin-right: var(--spacing-md);
}
.fx-buttongroup--is-inline > a,
.fx-buttongroup--is-inline > button {
  border-radius: 0;
  margin-right: -1px;
  width: auto;
}
.fx-buttongroup--is-inline > a:first-of-type,
.fx-buttongroup--is-inline > button:first-of-type {
  border-bottom-left-radius: var(--border-radius-xl);
  border-right: 0;
  border-top-left-radius: var(--border-radius-xl);
}
.fx-buttongroup--is-inline > a:last-of-type,
.fx-buttongroup--is-inline > button:last-of-type {
  border-bottom-right-radius: var(--border-radius-xl);
  border-left: 0;
  border-top-right-radius: var(--border-radius-xl);
}
.fx-buttongroup--no-margin > a,
.fx-buttongroup--no-margin > button {
  margin-bottom: 0;
}
.fx-buttongroup--left {
  justify-content: left;
}
.fx-buttongroup--center {
  justify-content: center;
}
.fx-buttongroup--is-stacked {
  flex-direction: column;
}
.fx-buttongroup--is-stacked > a,
.fx-buttongroup--is-stacked > button {
  margin-bottom: var(--spacing-md);
  width: 100%;
}
.CalendarDay__default {
  background: var(--white);
  border-color: inherit;
  vertical-align: middle;
}
.CalendarDay__default:hover {
  background: var(--n200);
  border-color: inherit;
  color: inherit;
}
.CalendarDay__hovered_span {
  background: var(--p100);
  color: inherit;
}
.CalendarDay__hovered_span:hover {
  background: var(--n400);
  border-color: var(--n400);
}
.CalendarDay__hovered_span:active {
  background: var(--n300);
}
.CalendarDay__selected_span {
  background: var(--p100);
  border-color: var(--p200);
  color: inherit;
}
.CalendarDay__selected_span:hover {
  background: var(--p400);
  border-color: var(--p400);
  color: #fff;
}
.CalendarDay__last_in_range {
  border-right-color: var(--p400);
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: var(--p500);
  border-color: var(--p500);
  color: var(--white);
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: transparent;
  border-color: var(--n200);
  color: var(--n200);
}
.DateRangePickerInput,
.SingleDatePickerInput {
  background-color: var(--white);
  padding: var(--spacing-sm) var(--spacing-md);
  transition: border-color var(--transition-default);
  width: 100%;
}
.DateRangePickerInput.DateRangePickerInput__withBorder,
.DateRangePickerInput.SingleDatePickerInput__withBorder,
.SingleDatePickerInput.DateRangePickerInput__withBorder,
.SingleDatePickerInput.SingleDatePickerInput__withBorder {
  border-color: inherit;
  border-radius: var(--border-radius-md);
}
.DateRangePickerInput.DateRangePickerInput__showClearDates,
.SingleDatePickerInput.DateRangePickerInput__showClearDates {
  padding-right: 30px;
}
.DateRangePickerInput__disabled {
  background: #f2f2f2;
}
.DateRangePickerInput_arrow {
  height: 24px;
  margin: 0 12px;
  vertical-align: bottom;
  width: 24px;
}
.DateInput {
  width: 90px;
}
.DateInput_input {
  background-color: inherit;
  border: none;
  font-size: var(--font-size-md);
  padding: 0;
  width: 100%;
}
.DateInput_input::placeholder {
  transition: color var(--default-transition);
}
.DateInput_fang {
  margin-top: -8px;
}
.DateRangePicker svg {
  vertical-align: middle;
}
.fx-card {
  background-color: #fff;
}
body.dark .fx-card {
  background-color: var(--n600);
}
.fx-card {
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 12px;
}
.fx-card--shadowsingle {
  box-shadow: 0 0 0 1px var(--shadow), 0 0 1px 0 var(--shadow),
    0 2px 2px 0 var(--shadow);
}
.fx-card--shadowdouble {
  box-shadow: 0 0 0 1px var(--shadow), 0 2px 2px 0 var(--shadow),
    0 4px 4px 0 var(--shadow);
}
.fx-card--shadowtriple {
  box-shadow: 0 0 0 1px var(--shadow), 0 4px 4px 0 var(--shadow),
    0 8px 8px 0 var(--shadow);
}
.fx-card--xs {
  padding: var(--spacing-xs);
}
.fx-card--sm {
  padding: var(--spacing-sm);
}
.fx-card--md {
  padding: var(--spacing-md);
}
.fx-card--lg {
  padding: var(--spacing-lg);
}
.fx-card--xl {
  padding: var(--spacing-xl);
}
.fx-card-fx-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-md);
}
.fx-card-fx-header--is-stacked {
  flex-direction: column;
}
.fx-card-fx-footer {
  background-color: var(--n100);
}
body.dark .fx-card-fx-footer {
  background-color: var(--n600);
}
.fx-card-fx-footer {
  border-bottom-left-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
  display: block;
  margin: var(--spacing-md) calc(var(--spacing-md) * -1)
    calc(var(--spacing-md) * -1);
  padding: var(--spacing-md);
}
.fx-card-fx-footer--right {
  justify-content: flex-end;
}
.fx-carousel {
  overflow: hidden;
}
.fx-carousel-slides {
  overflow: hidden;
  position: relative;
}
.fx-carousel-slides img {
  height: 100%;
  max-height: none;
  object-fit: cover;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.fx-carousel-controls {
  display: flex;
  padding-bottom: var(--spacing-md);
  padding-top: var(--spacing-md);
}
.fx-carousel-controls .next,
.fx-carousel-controls .prev {
  cursor: pointer;
  user-select: none;
  z-index: 2;
}
.fx-carousel-controls .next {
  right: 10px;
}
.fx-carousel-controls .prev {
  left: 10px;
}
.fx-checkbox-tick {
  align-items: center;
  border: 2px solid;
  border-color: inherit;
  border-image: none 100%/1/0 stretch;
  border-radius: var(--border-radius-md);
  color: var(--white);
  display: inline-flex;
  flex-shrink: 0;
  height: 1.2rem;
  justify-content: center;
  margin-right: var(--spacing-sm);
  opacity: 1;
  transition: background-color 0.12s ease 0s, box-shadow 0.25s ease 0s;
  user-select: none;
  width: 1.2rem;
}
.fx-checkbox-tick-icon {
  backface-visibility: hidden;
  color: currentColor;
  display: inline-block;
  flex-shrink: 0;
  height: 12px;
  transition: transform 0.24s ease 0s, opacity 0.24s ease 0s;
  vertical-align: middle;
  width: 12px;
}
.fx-checkbox:checked + .fx-checkbox-tick,
.fx-checkbox[aria-checked="mixed"] + .fx-checkbox-tick {
  background-color: var(--p300);
  border-color: var(--p500);
}
.fx-chevron-icon {
  transform: translateY(13px);
  transition: all var(--transition-slow);
}
.fx-chevron-icon-line1,
.fx-chevron-icon-line2 {
  stroke: currentColor;
  stroke-linecap: round;
  stroke-width: 10;
  transform-origin: 50px 50px;
  transition: transform var(--transition-slow);
}
.fx-chevron-icon-line1 {
  transform: rotate(40deg);
}
.fx-chevron-icon-line2 {
  transform: rotate(-40deg);
}
.fx-chevron--is-active .fx-chevron-icon {
  transform: translateY(-13px);
}
.fx-chevron--is-active .fx-chevron-icon-line1 {
  transform: rotate(-40deg);
}
.fx-chevron--is-active .fx-chevron-icon-line2 {
  transform: rotate(40deg);
}
.fx-chevron--horizontal .fx-chevron-icon {
  transform: translateY(100px) translateX(13px) rotate(-90deg);
}
.fx-chevron--horizontal.fx-chevron--is-active .fx-chevron-icon {
  transform: translateY(100px) translateX(-13px) rotate(-90deg);
}
.fx-hr {
  border: 0 solid;
  border-color: inherit;
  opacity: 0.6;
}
.fx-hr--vertical {
  border-left-width: 1px;
  height: 100%;
}
.fx-hr--horizontal {
  border-bottom-width: 1px;
  width: 100%;
}
.fx-grid {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}
.fx-grid--is-fluid {
  padding-left: var(--outer-margin, 2rem);
  padding-right: var(--outer-margin, 2rem);
}
.fx-grid .fx-row {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  margin-left: var(--gutter-compensation, -0.5rem);
  margin-right: var(--gutter-compensation, -0.5rem);
}
.fx-grid .fx-row-direction--column {
  flex-direction: column;
}
.fx-grid .fx-row-direction--column-reverse {
  flex-direction: column-reverse;
}
.fx-grid .fx-row-direction--row {
  flex-direction: row;
}
.fx-grid .fx-row-direction--row-reverse {
  flex-direction: row-reverse;
}
.fx-grid .fx-row-wrap--wrap {
  flex-wrap: wrap;
}
.fx-grid .fx-row-wrap--nowrap {
  flex-wrap: nowrap;
}
.fx-grid .fx-row-wrap--wrap-reverse {
  flex-wrap: wrap-reverse;
}
.fx-grid .fx-row-aligncontent--center {
  align-content: center;
}
.fx-grid .fx-row-aligncontent--flex-start {
  align-content: flex-start;
}
.fx-grid .fx-row-aligncontent--flex-end {
  align-content: flex-end;
}
.fx-grid .fx-row-aligncontent--space-between {
  align-content: space-between;
}
.fx-grid .fx-row-aligncontent--space-around {
  align-content: space-around;
}
.fx-grid .fx-row-aligncontent--space-evenly {
  align-content: space-evenly;
}
.fx-grid .fx-row-aligncontent--initial {
  align-content: normal;
}
.fx-grid .fx-row-aligncontent--inherit {
  align-content: inherit;
}
.fx-grid .fx-row-alignitems--center {
  align-items: center;
}
.fx-grid .fx-row-alignitems--flex-start {
  align-items: flex-start;
}
.fx-grid .fx-row-alignitems--flex-end {
  align-items: flex-end;
}
.fx-grid .fx-row-alignitems--baseline {
  align-items: baseline;
}
.fx-grid .fx-row-alignitems--initial {
  align-items: normal;
}
.fx-grid .fx-row-alignitems--inherit {
  align-items: inherit;
}
.fx-grid .fx-row-justifycontent--center {
  justify-content: center;
}
.fx-grid .fx-row-justifycontent--flex-start {
  justify-content: flex-start;
}
.fx-grid .fx-row-justifycontent--flex-end {
  justify-content: flex-end;
}
.fx-grid .fx-row-justifycontent--space-between {
  justify-content: space-between;
}
.fx-grid .fx-row-justifycontent--space-around {
  justify-content: space-around;
}
.fx-grid .fx-row-justifycontent--space-evenly {
  justify-content: space-evenly;
}
.fx-grid .fx-row-justifycontent--initial {
  justify-content: normal;
}
.fx-grid .fx-row-justifycontent--inherit {
  justify-content: inherit;
}
.fx-grid .fx-row-justifyitems--auto,
.fx-grid .fx-row-justifyitems--baseline,
.fx-grid .fx-row-justifyitems--first-baseline,
.fx-grid .fx-row-justifyitems--last-baseline,
.fx-grid .fx-row-justifyitems--normal {
  justify-items: center;
}
.fx-grid .fx-row-justifyitems--flex-start {
  justify-items: flex-start;
}
.fx-grid .fx-row-justifyitems--flex-end {
  justify-items: flex-end;
}
.fx-grid .fx-row-justifyitems--start {
  justify-items: start;
}
.fx-grid .fx-row-justifyitems--center {
  justify-items: center;
}
.fx-grid .fx-row-justifyitems--end {
  justify-items: end;
}
.fx-grid .fx-row-justifyitems--self-start {
  justify-items: self-start;
}
.fx-grid .fx-row-justifyitems--self-end {
  justify-items: self-end;
}
.fx-grid .fx-row-justifyitems--left {
  justify-items: left;
}
.fx-grid .fx-row-justifyitems--right {
  justify-items: right;
}
.fx-grid .fx-row-justifyitems--safe {
  justify-items: safe;
}
.fx-grid .fx-row-justifyitems--unsafe {
  justify-items: unsafe;
}
.fx-grid .fx-row-justifyitems--initial {
  justify-items: legacy;
}
.fx-grid .fx-row-justifyitems--inherit {
  justify-items: inherit;
}
.fx-grid .fx-col {
  padding-left: var(--half-gutter-width, 0.5rem);
  padding-right: var(--half-gutter-width, 0.5rem);
}
.fx-grid .fx-col--no-padding {
  padding-left: 0;
  padding-right: 0;
}
.fx-grid .fx-col--is-reverse {
  flex-direction: column-reverse;
}
.fx-grid .fx-col--is-first {
  order: -1;
}
.fx-grid .fx-col--is-last {
  order: 1;
}
.fx-grid .fx-col-alignself--flex-start {
  align-self: flex-start;
}
.fx-grid .fx-col-alignself--flex-end {
  align-self: flex-end;
}
.fx-grid .fx-col-alignself--center {
  align-self: center;
}
.fx-grid .fx-col-alignself--baseline {
  align-self: baseline;
}
.fx-grid .fx-col-justifyself--flex-start {
  align-self: flex-start;
}
.fx-grid .fx-col-justifyself--flex-end {
  align-self: flex-end;
}
.fx-grid .fx-col-justifyself--center {
  align-self: center;
}
.fx-grid .fx-col-justifyself--baseline {
  align-self: baseline;
}
.fx-grid .fx-col_xs--1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}
.fx-grid .fx-col_xs--2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}
.fx-grid .fx-col_xs--3 {
  flex-basis: 25%;
  max-width: 25%;
}
.fx-grid .fx-col_xs--4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}
.fx-grid .fx-col_xs--5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}
.fx-grid .fx-col_xs--6 {
  flex-basis: 50%;
  max-width: 50%;
}
.fx-grid .fx-col_xs--7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}
.fx-grid .fx-col_xs--8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}
.fx-grid .fx-col_xs--9 {
  flex-basis: 75%;
  max-width: 75%;
}
.fx-grid .fx-col_xs--10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}
.fx-grid .fx-col_xs--11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}
.fx-grid .fx-col_xs--12 {
  flex-basis: 100%;
  max-width: 100%;
}
.fx-grid .fx-col_xs_offset--0 {
  margin-left: 0;
}
.fx-grid .fx-col_xs_offset--1 {
  margin-left: 8.33333333%;
}
.fx-grid .fx-col_xs_offset--2 {
  margin-left: 16.66666667%;
}
.fx-grid .fx-col_xs_offset--3 {
  margin-left: 25%;
}
.fx-grid .fx-col_xs_offset--4 {
  margin-left: 33.33333333%;
}
.fx-grid .fx-col_xs_offset--5 {
  margin-left: 41.66666667%;
}
.fx-grid .fx-col_xs_offset--6 {
  margin-left: 50%;
}
.fx-grid .fx-col_xs_offset--7 {
  margin-left: 58.33333333%;
}
.fx-grid .fx-col_xs_offset--8 {
  margin-left: 66.66666667%;
}
.fx-grid .fx-col_xs_offset--9 {
  margin-left: 75%;
}
.fx-grid .fx-col_xs_offset--10 {
  margin-left: 83.33333333%;
}
.fx-grid .fx-col_xs_offset--11 {
  margin-left: 91.66666667%;
}
@media only screen and (min-width: 480px) {
  .fx-grid .fx-col .container {
    width: var(--container-sm, 46rem);
  }
  .fx-grid .fx-col_sm--1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .fx-grid .fx-col_sm--2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .fx-grid .fx-col_sm--3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .fx-grid .fx-col_sm--4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .fx-grid .fx-col_sm--5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .fx-grid .fx-col_sm--6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .fx-grid .fx-col_sm--7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .fx-grid .fx-col_sm--8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .fx-grid .fx-col_sm--9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .fx-grid .fx-col_sm--10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .fx-grid .fx-col_sm--11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .fx-grid .fx-col_sm--12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .fx-grid .fx-col_sm_offset--0 {
    margin-left: 0;
  }
  .fx-grid .fx-col_sm_offset--1 {
    margin-left: 8.33333333%;
  }
  .fx-grid .fx-col_sm_offset--2 {
    margin-left: 16.66666667%;
  }
  .fx-grid .fx-col_sm_offset--3 {
    margin-left: 25%;
  }
  .fx-grid .fx-col_sm_offset--4 {
    margin-left: 33.33333333%;
  }
  .fx-grid .fx-col_sm_offset--5 {
    margin-left: 41.66666667%;
  }
  .fx-grid .fx-col_sm_offset--6 {
    margin-left: 50%;
  }
  .fx-grid .fx-col_sm_offset--7 {
    margin-left: 58.33333333%;
  }
  .fx-grid .fx-col_sm_offset--8 {
    margin-left: 66.66666667%;
  }
  .fx-grid .fx-col_sm_offset--9 {
    margin-left: 75%;
  }
  .fx-grid .fx-col_sm_offset--10 {
    margin-left: 83.33333333%;
  }
  .fx-grid .fx-col_sm_offset--11 {
    margin-left: 91.66666667%;
  }
}
@media only screen and (min-width: 768px) {
  .fx-grid .fx-col .container {
    width: var(--container-md, 61rem);
  }
  .fx-grid .fx-col_md--1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .fx-grid .fx-col_md--2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .fx-grid .fx-col_md--3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .fx-grid .fx-col_md--4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .fx-grid .fx-col_md--5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .fx-grid .fx-col_md--6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .fx-grid .fx-col_md--7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .fx-grid .fx-col_md--8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .fx-grid .fx-col_md--9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .fx-grid .fx-col_md--10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .fx-grid .fx-col_md--11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .fx-grid .fx-col_md--12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .fx-grid .fx-col_md_offset--0 {
    margin-left: 0;
  }
  .fx-grid .fx-col_md_offset--1 {
    margin-left: 8.33333333%;
  }
  .fx-grid .fx-col_md_offset--2 {
    margin-left: 16.66666667%;
  }
  .fx-grid .fx-col_md_offset--3 {
    margin-left: 25%;
  }
  .fx-grid .fx-col_md_offset--4 {
    margin-left: 33.33333333%;
  }
  .fx-grid .fx-col_md_offset--5 {
    margin-left: 41.66666667%;
  }
  .fx-grid .fx-col_md_offset--6 {
    margin-left: 50%;
  }
  .fx-grid .fx-col_md_offset--7 {
    margin-left: 58.33333333%;
  }
  .fx-grid .fx-col_md_offset--8 {
    margin-left: 66.66666667%;
  }
  .fx-grid .fx-col_md_offset--9 {
    margin-left: 75%;
  }
  .fx-grid .fx-col_md_offset--10 {
    margin-left: 83.33333333%;
  }
  .fx-grid .fx-col_md_offset--11 {
    margin-left: 91.66666667%;
  }
}
@media only screen and (min-width: 960px) {
  .fx-grid .fx-col .container {
    width: var(--container-lg, 71rem);
  }
  .fx-grid .fx-col_lg--1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .fx-grid .fx-col_lg--2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .fx-grid .fx-col_lg--3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .fx-grid .fx-col_lg--4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .fx-grid .fx-col_lg--5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .fx-grid .fx-col_lg--6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .fx-grid .fx-col_lg--7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .fx-grid .fx-col_lg--8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .fx-grid .fx-col_lg--9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .fx-grid .fx-col_lg--10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .fx-grid .fx-col_lg--11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .fx-grid .fx-col_lg--12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .fx-grid .fx-col_lg_offset--0 {
    margin-left: 0;
  }
  .fx-grid .fx-col_lg_offset--1 {
    margin-left: 8.33333333%;
  }
  .fx-grid .fx-col_lg_offset--2 {
    margin-left: 16.66666667%;
  }
  .fx-grid .fx-col_lg_offset--3 {
    margin-left: 25%;
  }
  .fx-grid .fx-col_lg_offset--4 {
    margin-left: 33.33333333%;
  }
  .fx-grid .fx-col_lg_offset--5 {
    margin-left: 41.66666667%;
  }
  .fx-grid .fx-col_lg_offset--6 {
    margin-left: 50%;
  }
  .fx-grid .fx-col_lg_offset--7 {
    margin-left: 58.33333333%;
  }
  .fx-grid .fx-col_lg_offset--8 {
    margin-left: 66.66666667%;
  }
  .fx-grid .fx-col_lg_offset--9 {
    margin-left: 75%;
  }
  .fx-grid .fx-col_lg_offset--10 {
    margin-left: 83.33333333%;
  }
  .fx-grid .fx-col_lg_offset--11 {
    margin-left: 91.66666667%;
  }
}
@media only screen and (min-width: 1280px) {
  .fx-grid .fx-col .container {
    width: var(--container-xl, 71rem);
  }
  .fx-grid .fx-col_xl--1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .fx-grid .fx-col_xl--2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .fx-grid .fx-col_xl--3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .fx-grid .fx-col_xl--4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .fx-grid .fx-col_xl--5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .fx-grid .fx-col_xl--6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .fx-grid .fx-col_xl--7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .fx-grid .fx-col_xl--8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .fx-grid .fx-col_xl--9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .fx-grid .fx-col_xl--10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .fx-grid .fx-col_xl--11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .fx-grid .fx-col_xl--12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .fx-grid .fx-col_xl_offset--0 {
    margin-left: 0;
  }
  .fx-grid .fx-col_xl_offset--1 {
    margin-left: 8.33333333%;
  }
  .fx-grid .fx-col_xl_offset--2 {
    margin-left: 16.66666667%;
  }
  .fx-grid .fx-col_xl_offset--3 {
    margin-left: 25%;
  }
  .fx-grid .fx-col_xl_offset--4 {
    margin-left: 33.33333333%;
  }
  .fx-grid .fx-col_xl_offset--5 {
    margin-left: 41.66666667%;
  }
  .fx-grid .fx-col_xl_offset--6 {
    margin-left: 50%;
  }
  .fx-grid .fx-col_xl_offset--7 {
    margin-left: 58.33333333%;
  }
  .fx-grid .fx-col_xl_offset--8 {
    margin-left: 66.66666667%;
  }
  .fx-grid .fx-col_xl_offset--9 {
    margin-left: 75%;
  }
  .fx-grid .fx-col_xl_offset--10 {
    margin-left: 83.33333333%;
  }
  .fx-grid .fx-col_xl_offset--11 {
    margin-left: 91.66666667%;
  }
}
.fx-grid .fx-col--is-stretch {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.fx-hamburger {
  position: relative;
}
.fx-hamburger:hover {
  cursor: pointer;
}
.fx-hamburger-line {
  background-color: currentColor;
  border-radius: var(--border-radius-md);
  display: block;
  height: 5px;
  margin: 8px auto;
  transition: all 0.3s ease-in-out;
  width: 50px;
}
.fx-hamburger--standard.fx-hamburger--is-active
  .fx-hamburger-line:nth-child(2) {
  opacity: 0;
}
.fx-hamburger--standard.fx-hamburger--is-active .fx-hamburger-line:first-child {
  transform: translateY(13px) rotate(45deg);
}
.fx-hamburger--standard.fx-hamburger--is-active
  .fx-hamburger-line:nth-child(3) {
  transform: translateY(-13px) rotate(-45deg);
}
.fx-hamburger--leftArrow.fx-hamburger--is-active
  .fx-hamburger-line:nth-child(2) {
  opacity: 1;
}
.fx-hamburger--leftArrow.fx-hamburger--is-active .fx-hamburger-line:first-child,
.fx-hamburger--leftArrow.fx-hamburger--is-active
  .fx-hamburger-line:nth-child(3) {
  width: 40px;
}
.fx-hamburger--leftArrow.fx-hamburger--is-active
  .fx-hamburger-line:first-child {
  transform: translateX(-10px) rotate(-45deg);
}
.fx-hamburger--leftArrow.fx-hamburger--is-active
  .fx-hamburger-line:nth-child(3) {
  transform: translateX(-10px) rotate(45deg);
}
.fx-hamburger--rightArrow.fx-hamburger--is-active
  .fx-hamburger-line:nth-child(2) {
  opacity: 1;
}
.fx-hamburger--rightArrow.fx-hamburger--is-active
  .fx-hamburger-line:first-child,
.fx-hamburger--rightArrow.fx-hamburger--is-active
  .fx-hamburger-line:nth-child(3) {
  width: 40px;
}
.fx-hamburger--rightArrow.fx-hamburger--is-active
  .fx-hamburger-line:first-child {
  transform: translateX(10px) rotate(45deg);
}
.fx-hamburger--rightArrow.fx-hamburger--is-active
  .fx-hamburger-line:nth-child(3) {
  transform: translateX(10px) rotate(-45deg);
}
.fx-hamburger--circle,
.fx-hamburger--circle:before {
  transition: all 0.3s ease-in-out;
}
.fx-hamburger--circle:before {
  border: 5px solid transparent;
  border-radius: 100%;
  box-sizing: border-box;
  content: "";
  height: 70px;
  left: calc(50% - 35px);
  position: absolute;
  top: calc(50% - 35px);
  width: 70px;
}
.fx-hamburger--circle.fx-hamburger--is-active {
  transform: rotate(45deg);
}
.fx-hamburger--circle.fx-hamburger--is-active:before {
  border-color: currentColor;
}
.fx-hamburger--circle.fx-hamburger--is-active .fx-hamburger-line {
  width: 35px;
}
.fx-hamburger--circle.fx-hamburger--is-active .fx-hamburger-line:nth-child(2) {
  opacity: 0;
}
.fx-hamburger--circle.fx-hamburger--is-active .fx-hamburger-line:first-child {
  transform: translateY(13px);
}
.fx-hamburger--circle.fx-hamburger--is-active .fx-hamburger-line:nth-child(3) {
  transform: translateY(-13px) rotate(90deg);
}
.header {
  padding: 24px;
}
.fx-heading {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-xl);
}
.fx-heading--no-margin {
  margin-bottom: 0;
}
.fx-heading--xs {
  font-size: var(--font-size-xs);
}
.fx-heading--sm {
  font-size: var(--font-size-sm);
}
.fx-heading--lg {
  font-size: var(--font-size-lg);
}
.fx-heading--xl {
  font-size: var(--font-size-xl);
}
.fx-heading--xxl {
  font-size: var(--font-size-xxl);
}
.fx-heading--3xl {
  font-size: var(--font-size-3xl);
}
.fx-heading--4xl {
  font-size: var(--font-size-4xl);
}
.fx-hero {
  padding: var(--spacing-4xl, 3rem) 0;
}
.fx-hero--is-rounded {
  border-radius: var(--border-radius-lg);
}
.fx-hero-content-text .heading {
  margin-bottom: 0;
  margin-top: 0;
}
.fx-hero-content-text .text--no-cta {
  margin-top: 0;
}
.fx-hero-content-text:only-child {
  margin: auto;
  max-width: 5000px;
  text-align: center;
}
.fx-hero-content-cta {
  margin-top: var(--spacing-xxl);
}
.fx-hero-content-custom img,
.fx-hero-content-custom svg {
  max-width: 100%;
}
.fx-hero--is-fullwidth {
  max-width: none;
}
.fx-hero--is-fullwidth .hero-content {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width);
}
.fx-hero--is-stacked {
  text-align: center;
}
.fx-hero--is-stacked .fx-hero-content-text {
  margin-bottom: calc(var(--spacing-xxl) * 2);
}
.fx-hero--is-stacked .fx-hero-content-text .text {
  margin-top: var(--spacing-md);
}
.fx-iconbutton {
  padding: var(--spacing-sm);
}
.fx-iconbutton--lg {
  padding: var(--spacing-md);
}
.fx-iconbutton--rounded {
  border-radius: var(--border-radius-circle);
}
.fx-image {
  display: block;
  height: auto;
  max-height: 100%;
}
.fx-image--is-circle {
  border-radius: var(--border-radius-circle);
}
.fx-image--has-border {
  border: 2px solid var(--bg-light);
}
body.dark .fx-image--has-border {
  border-color: var(--bg-dark);
}
.fx-input-label {
  font-weight: var(--font-weight-bold);
}
.fx-input-description {
  font-size: var(--font-size-sm);
}
.fx-input-inner {
  display: flex;
  margin-top: var(--spacing-md);
  position: relative;
}
.fx-input-inner--inline {
  margin-left: var(--spacing-md);
  margin-right: var(--spacing-md);
}
.fx-input-inner .fx-input {
  appearance: none;
  background-color: #fff;
  border-radius: var(--border-radius-md);
  border-style: solid;
  border-width: 1px;
  font-size: var(--font-size-md);
  padding: var(--spacing-md) var(--spacing-md);
  transition: padding var(--transition-default);
}
.fx-input-inner .fx-input--is-fullwidth {
  width: 100%;
}
.fx-input-inner .fx-input:focus {
  box-shadow: 0 0 0 4px var(--p300);
  outline: 0;
}
.fx-input-inner .fx-input::placeholder {
  color: var(--n500);
  transition: color var(--transition-default);
}
.fx-input-inner .fx-input--has-warning:not(:focus)::placeholder {
  color: var(--warning);
}
.fx-input-inner .fx-input--has-warning {
  border-color: var(--warning);
}
.fx-input-inner .fx-input--is-invalid:not(:focus)::placeholder {
  color: var(--error);
}
.fx-input-inner .fx-input--is-invalid {
  border-color: var(--error);
}
.fx-input-inner .fx-input--is-valid:not(:focus)::placeholder {
  color: var(--success);
}
.fx-input-inner .fx-input--is-valid {
  border-color: var(--success);
}
.fx-input-inner .fx-input--is-disabled,
.fx-input-inner .fx-input:disabled,
.fx-input-inner .fx-input[readonly] {
  background-color: var(--n200);
}
.fx-input-inner .fx-input--is-disabled:hover,
.fx-input-inner .fx-input:disabled:hover,
.fx-input-inner .fx-input[readonly]:hover {
  cursor: not-allowed;
}
.fx-input-inner .fx-input--right {
  text-align: right;
}
.fx-input-inner .fx-input--prefix {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.fx-input-inner .fx-input--suffix {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.fx-input-inner .fx-input-prefix,
.fx-input-inner .fx-input-suffix {
  align-items: center;
  background-color: var(--n300);
  border-bottom-color: var(--n300);
  border-top-color: var(--n300);
  color: var(--n600);
  display: flex;
  flex: 0 0 auto;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.fx-input-inner .fx-input-prefix {
  border-left-color: var(--n300);
  border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
  border-right: 1px solid transparent;
  margin-right: -1px;
}
.fx-input-inner .fx-input-suffix {
  border-left: 1px solid transparent;
  border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
  border-right-color: var(--n300);
  margin-left: -1px;
}
.label {
  display: block;
  font-size: var(--font-size-sm);
}
.label--inline {
  align-items: center;
  display: flex;
}
.label:hover {
  cursor: pointer;
}
.label--is-disabled {
  opacity: 0.8;
}
.label--is-disabled:hover {
  cursor: not-allowed;
}
.fx-link {
  cursor: pointer;
  text-decoration: underline;
  transition: all var(--transition-default);
}
.fx-link--hover,
.fx-link:hover {
  text-decoration: none;
}
.fx-link--active,
.fx-link--focus,
.fx-link:active,
.fx-link:focus {
  box-shadow: 0 0 0 4px var(--p300);
  outline: 0;
}
.fx-link-icon,
.fx-link svg {
  backface-visibility: hidden;
  color: currentColor;
  display: inline-block;
  flex-shrink: 0;
  height: 1em;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: middle;
  width: 1em;
}
.fx-list,
.fx-list--lg {
  margin-bottom: var(--spacing-md);
  margin-left: var(--spacing-xs);
  padding-left: var(--spacing-md);
}
.fx-list--lg {
  font-size: var(--font-size-lg);
}
.fx-list--xl {
  font-size: var(--font-size-xl);
  margin-bottom: var(--spacing-lg);
}
.fx-list--xl,
.fx-list--xxl {
  margin-left: var(--spacing-xs);
  padding-left: var(--spacing-md);
}
.fx-list--xxl {
  font-size: var(--font-size-xxl);
  margin-bottom: var(--spacing-xl);
}
.fx-list--no-margin {
  margin-bottom: 0;
}
.fx-list--no-style {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
.fx-list-item {
  margin-bottom: var(--spacing-md);
  margin-top: var(--spacing-md);
}
.fx-list-item--has-icon {
  align-items: center;
  display: flex;
}
.fx-list-item--has-icon svg {
  height: 1em;
  line-height: 1em;
  margin-right: 0.5rem;
  width: 1em;
}
.fx-loadingbutton {
  overflow: hidden;
  position: relative;
}
.fx-loadingbutton .fx-spinner {
  opacity: 0;
  position: absolute;
  transition: opacity var(--transition-default),
    visibility var(--transition-default);
  visibility: hidden;
}
.fx-loadingbutton-content {
  transform: scaleX(1);
  transition: opacity var(--transition-default),
    transform var(--transition-default), visibility var(--transition-default);
}
.fx-loadingbutton--is-loading .fx-spinner,
.fx-loadingbutton-content {
  opacity: 1;
  visibility: visible;
}
.fx-loadingbutton--is-loading .fx-loadingbutton-content {
  opacity: 0;
  transform: scale3d(0, 0, 0);
  visibility: hidden;
}
.fx-menu-list {
  max-width: 28rem;
  min-width: 240px;
}
.fx-menu-group {
  margin-bottom: 12px;
}
.fx-menu-group:last-of-type {
  margin-bottom: 0;
}
.fx-menu-group-title {
  font-weight: var(--font-weight-bold);
  padding: 0 var(--spacing-md);
}
.fx-menu--is-columns .menu-list {
  display: flex;
  flex-direction: row;
}
.fx-menu--is-columns .menu-group {
  flex-direction: column;
  margin-bottom: 0;
}
.fx-menu-item {
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  display: flex;
  padding: var(--spacing-md);
  text-decoration: none;
  transition: all var(--transition-default);
  width: 100%;
}
.fx-menu-item:hover {
  background-color: var(--p300);
}
.fx-menu-item .has-icon {
  margin-right: 12px;
}
.ReactModal__Overlay {
  background-color: var(--overlay);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 99;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content {
  bottom: 0;
  left: 50%;
  max-width: 720px;
  outline: none;
  overflow-y: auto;
  position: fixed;
  transform: translate(-50%);
  transition: transform 0.2s cubic-bezier(0, 0.37, 0.64, 1);
}
.ReactModal__Content--after-open {
  bottom: auto;
  opacity: 1;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateY(10vh);
}
.popover {
  animation: fadeIn 0.1s ease-in 0.1s forwards;
  opacity: 0;
  position: relative;
}
.popover-arrow {
  height: 10px;
  position: absolute;
  width: 10px;
}
.popover-arrow:after {
  background-color: #fff;
}
body.dark .popover-arrow:after {
  background-color: var(--n600);
}
.popover-arrow:after {
  box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  content: "";
  height: 10px;
  left: 0;
  position: absolute;
  top: -4px;
  transform: rotate(45deg);
  width: 10px;
}
.popover-arrow[data-popper-placement^="bottom"] {
  bottom: -5px;
}
.popover-arrow[data-popper-placement^="bottom"]:after {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.popover-arrow[data-hide] {
  visibility: hidden;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fx-postlist-item {
  padding: var(--spacing-md);
}
.fx-postlist-item-content-text {
  margin-bottom: calc(var(--spacing-xxl) * 2);
}
.fx-postlist-item-content-text .heading {
  margin-top: 0;
}
.fx-postlist-item-content-image {
  padding: 0 !important;
}
.fx-postlist-item-content-image img,
.fx-postlist-item-content-image svg {
  max-width: 100%;
}
.fx-pricingtable-header {
  margin-bottom: var(--spacing-xl);
}
.fx-pricingtable-header .text {
  margin-top: 0;
}
.fx-pricingtable .card-header {
  border-bottom: 1px solid var(--n200);
  padding-bottom: var(--spacing-xl);
}
.fx-pricingtable-content-column .text,
.fx-pricingtable .card-header .heading {
  margin-top: 0;
}
.fx-pricingtable-content-column-price {
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-bold);
}
.fx-radio {
  align-items: center;
  border: 2px solid;
  border-color: inherit;
  border-image: none 100%/1/0 stretch;
  color: var(--white);
  display: inline-flex;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  margin-right: var(--spacing-sm);
  transition: background-color 0.12s ease 0s, box-shadow 0.25s ease 0s;
  user-select: none;
  width: 1.25rem;
}
.fx-radio,
.fx-radio > span {
  border-radius: var(--border-radius-pill);
}
.fx-radio > span {
  background-color: currentColor;
  height: 50%;
  width: 50%;
}
.fx-radio--is-checked {
  background-color: var(--p400);
  border-color: var(--p500);
}
.fx-radio--is-checked--is-disabled {
  background-color: var(--n200);
}
.fx-radio--is-disabled {
  opacity: 0.8;
}
.fx-radiogroup--horizontal .radioGroup-container {
  display: flex;
  flex-direction: column;
  flex-direction: row;
}
.fx-radiogroup--horizontal .radioGroup-container > label {
  margin-right: var(--spacing-lg);
}
.fx-radiogroup--vertical .fx-radiogroup-container label {
  margin-bottom: var(--spacing-lg);
}
.fx-search {
  display: inline-block;
}
.fx-search .input {
  border-bottom-right-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
  padding-right: 32px;
}
.fx-search .input-suffix {
  background-color: transparent;
  border: none;
  height: 100%;
  margin: 0;
  padding-right: 0;
  position: absolute;
  right: 0;
  width: auto;
}
.fx-search .input-suffix button {
  border: none;
  cursor: pointer;
  pointer-events: all;
}
.fx-select-label {
  font-weight: var(--font-weight-bold);
}
.fx-select-description {
  font-size: var(--font-size-sm);
}
.fx-select-inner {
  display: block;
  margin-top: 6px;
  position: relative;
}
.fx-select-inner-prefix {
  display: block;
  left: 0.5rem;
  padding: var(--border-radius-md);
  width: 2rem;
}
.fx-select-inner-icon,
.fx-select-inner-prefix {
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.fx-select-inner-icon {
  align-items: center;
  color: inherit;
  display: inline-flex;
  justify-content: center;
  right: 0.5rem;
  width: 1.5rem;
}
.fx-select {
  appearance: none;
  background-color: #fff;
  border: 1px solid;
  border-color: inherit;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  outline: none;
  overflow-x: hidden;
  padding: var(--spacing-md);
  position: relative;
  text-overflow: ellipsis;
  transition: box-shadow var(--transition-default),
    padding var(--transition-default);
  white-space: nowrap;
  width: 100%;
}
.fx-select:focus {
  box-shadow: 0 0 0 4px var(--p300);
  outline: 0;
}
.fx-select--disabled,
.fx-select:disabled,
.fx-select[readonly] {
  background-color: var(--n200);
  cursor: not-allowed;
}
.fx-select--has-warning:not(:focus)::placeholder {
  color: var(--warning);
}
.fx-select--has-warning {
  border-color: var(--warning);
}
.fx-select--is-invalid:not(:focus)::placeholder {
  color: var(--error);
}
.fx-select--is-invalid {
  border-color: var(--error);
}
.fx-select--is-valid:not(:focus)::placeholder {
  color: var(--success);
}
.fx-select--is-valid {
  border-color: var(--success);
}
.fx-select--has-prefix {
  padding-left: 2.5rem;
}
.fx-spacing--top {
  margin-top: var(--spacing-md);
}
.fx-spacing--bottom {
  margin-bottom: var(--spacing-md);
}
.fx-spinner {
  animation: Spin 1s linear infinite;
  border: 1px solid;
  border-radius: var(--border-radius-circle);
  border-top-color: transparent !important;
  display: block;
  height: var(--icon-size-md);
  transform-origin: 50% 50%;
  width: var(--icon-size-md);
}
.fx-spinner--md {
  height: var(--icon-size-lg);
  width: var(--icon-size-lg);
}
.fx-spinner--lg {
  height: var(--icon-size-xl);
  width: var(--icon-size-xl);
}
.fx-spinner--xl {
  height: var(--icon-size-xxl);
  width: var(--icon-size-xxl);
}
@keyframes Spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.fx-table {
  background-color: var(--white);
  border-collapse: collapse;
  box-shadow: var(--shadow) 0 0 0 1px, var(--shadow) 0 0 1px 0,
    var(--shadow) 0 2px 2px 0;
  height: 100%;
  position: relative;
  width: 100%;
}
.fx-table--noShadow {
  box-shadow: none;
}
.fx-table th {
  border-bottom: 1px solid;
  border-color: inherit;
  font-weight: var(--font-weight-bold);
  padding: var(--spacing-sm) var(--spacing-lg);
  text-align: left;
  transition: background-color 0.12s ease-in-out 0s, color 0.12s ease-in-out 0s;
  white-space: nowrap;
}
.fx-table th,
.fx-table tr {
  background-color: var(--white);
  position: relative;
  vertical-align: middle;
}
.fx-table tr {
  cursor: pointer;
}
.fx-table tr:nth-child(2n) {
  background-color: var(--n100);
}
.fx-table tr td {
  border-bottom: 1px solid;
  border-color: inherit;
  padding: var(--spacing-lg);
  text-align: left;
  transition: background-color 0.12s ease-in-out 0s;
  vertical-align: middle;
  white-space: nowrap;
}
.fx-table tr:focus td {
  box-shadow: var(--n100) 0 0 0 4px;
  outline: 0 none currentColor;
  transform: translate(0);
}
.fx-table tr:hover td {
  background-color: var(--n100);
  color: var(--p500);
}
.fx-table tr:hover:last-of-type td {
  border-bottom: 0;
}
.fx-table--is-condensed tr td,
.fx-table--is-condensed tr th {
  font-size: var(--font-size-sm);
  padding: var(--spacing-sm) var(--spacing-md);
}
.fx-table--noBorder tr td {
  border: 0;
}
.fx-tabs {
  display: block;
}
.fx-tabs-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.fx-tabs-list-tab {
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-md);
  justify-content: center;
  outline: 0 none currentColor;
  padding: 0.5rem 1rem;
  transition: all var(--transition-default);
}
.fx-tabs-list-tab--is-selected,
.fx-tabs-list-tab[aria-selected="true"] {
  color: var(--p400);
}
.fx-tabs-list-tab--is-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.fx-tabs-list-tab:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
  z-index: 1;
}
.fx-tabs-list-tab:active {
  background: #e2e8f0 none repeat scroll 0 0;
}
.fx-tabs-panels {
  width: 100%;
}
.fx-tabs-panels-panel {
  display: none;
  padding: 1rem;
}
.fx-tabs-panels-panel--is-selected {
  display: block;
}
.fx-tabs--enclosed .tabs-list {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: inherit;
}
.fx-tabs--enclosed .tabs-list .tabs-list-tab {
  border: 1px solid transparent;
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
  margin-bottom: -1px;
}
.fx-tabs--enclosed .tabs-list .tabs-list-tab--is-selected {
  border-bottom-color: var(--bg-light);
  border-left-color: inherit;
  border-right-color: inherit;
  border-top-color: inherit;
}
.fx-tabs--line .tabs-list {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: inherit;
}
.fx-tabs--line .tabs-list .tabs-list-tab {
  border-bottom: 2px solid transparent;
  border-color: transparent;
  margin-bottom: -2px;
}
.fx-tabs--line .tabs-list .tabs-list-tab--is-selected {
  border-color: currentColor;
}
.fx-tabs--rounded .tabs-list .tabs-list-tab--is-selected {
  background-color: var(--b500);
  border-radius: var(--border-radius-pill);
  color: #fff;
}
.fx-tabs--center .tabs-list {
  justify-content: center;
}
.fx-tabs--end .tabs-list {
  justify-content: flex-end;
}
.fx-tabs--is-stretched .tabs-list .tabs-list-tab {
  display: flex;
  flex: 1 1 0%;
}
.fx-tabs--sm .tabs-list .tabs-list-tab {
  font-size: var(--font-size-sm);
}
.fx-tabs--lg .tabs-list .tabs-list-tab {
  font-size: var(--font-size-lg);
}
.fx-tabs--vertical {
  display: flex;
}
.fx-tabs--vertical .tabs-list {
  flex-direction: column;
}
.fx-tabs--line.fx-tabs--vertical .tabs-list {
  border: 0;
  border-color: inherit;
  border-left-style: solid;
  border-left-width: 2px;
}
.fx-tabs--line.fx-tabs--vertical .tabs-list .tabs-list-tab {
  border: 0;
  border-color: transparent;
  border-left: 2px solid transparent;
  margin-bottom: 0;
  margin-left: -2px;
}
.fx-tabs--line.fx-tabs--vertical .tabs-list .tabs-list-tab--is-selected {
  border-color: currentColor;
}
.fx-text {
  font-weight: var(--font-weight-default);
  margin-bottom: var(--spacing-md);
}
.fx-text--no-margin {
  margin-bottom: 0;
}
.fx-text--is-strikethrough {
  text-decoration: line-through;
}
.fx-text--is-bold {
  font-weight: var(--font-weight-bold);
}
.fx-text--is-italic {
  font-style: italic;
}
.fx-text--xs {
  font-size: var(--font-size-xs);
}
.fx-text--sm {
  font-size: var(--font-size-sm);
}
.fx-text--lg {
  font-size: var(--font-size-lg);
}
.fx-text--xl {
  font-size: var(--font-size-xl);
}
.fx-text--xxl {
  font-size: var(--font-size-xxl);
}
.fx-text--3xl {
  font-size: var(--font-size-3xl);
}
.fx-text--4xl {
  font-size: var(--font-size-4xl);
}
.fx-toasts {
  bottom: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  max-width: 400px;
  padding: 16px;
  position: fixed;
  right: 0;
  transition: var(--transtion-default);
  width: 100%;
}
.fx-toasts--left {
  left: 0;
}
.fx-toasts--right {
  right: 0;
}
.fx-toasts--top {
  top: 0;
}
.fx-toasts--bottom {
  bottom: 0;
}
.fx-toasts .toast {
  animation: slidein--bottom 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    forwards;
  transition: var(--transtion-default);
}
@keyframes slidein--bottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fx-toggle-inner {
  height: calc(var(--spacing-md) * 2);
  position: relative;
  width: calc(var(--spacing-md) * 4);
}
.fx-toggle-inner--sm {
  height: calc(var(--spacing-sm) * 2);
  width: calc(var(--spacing-sm) * 4);
}
.fx-toggle-inner--lg {
  height: calc(var(--spacing-lg) * 2);
  width: calc(var(--spacing-lg) * 4);
}
.fx-toggle {
  background-color: var(--n500);
  border-radius: var(--border-radius-pill);
  bottom: 0;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
}
.fx-toggle,
.fx-toggle:before {
  position: absolute;
  transition: all var(--transition-default);
}
.fx-toggle:before {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 80%;
  left: 6%;
  top: 50%;
  transform: translateY(-50%);
  width: 40%;
  z-index: 0;
}
.fx-toggle--is-checked {
  background-color: var(--success);
}
.fx-toggle--is-checked:before {
  transform: translate(120%, -50%);
}
.fx-toggle--is-disabled {
  background-color: var(--n300);
  cursor: not-allowed;
}
.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}
[data-tippy-root] {
  max-width: calc(100vw - 10px);
}
.tippy-box {
  background-color: #333;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  position: relative;
  transition-property: transform, visibility, opacity;
}
.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-top-color: initial;
  border-width: 8px 8px 0;
  bottom: -7px;
  left: 0;
  transform-origin: center top;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-bottom-color: initial;
  border-width: 0 8px 8px;
  left: 0;
  top: -7px;
  transform-origin: center bottom;
}
.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-left-color: initial;
  border-width: 8px 0 8px 8px;
  right: -7px;
  transform-origin: center left;
}
.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-right-color: initial;
  border-width: 8px 8px 8px 0;
  left: -7px;
  transform-origin: center right;
}
.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow {
  color: #333;
  height: 16px;
  width: 16px;
}
.tippy-arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  position: absolute;
}
.tippy-content {
  padding: 5px 9px;
  position: relative;
  z-index: 1;
}
.fx-validationhint {
  align-items: center;
  color: var(--n600);
  display: flex;
  margin-top: 12px;
  transition: color var(--transition-default);
}
.fx-validationhint--has-warning {
  color: var(--warning);
}
.fx-validationhint--is-invalid {
  color: var(--error);
}
.fx-validationhint--is-valid {
  color: var(--success);
}
.fx-validationhint-icon {
  color: inherit;
  flex-shrink: 1;
  height: 24px;
  margin-right: 12px;
  width: 24px;
}
.fx-validationhint--no-margin {
  margin-top: 0;
}

.darkTableClass > li > button.active  {
  color: white !important;
  background-color: #17191D !important;
  border-width: 0 !important;
}

.darkTableClass > li > button  {
  color: #B8B9BD !important;
}
