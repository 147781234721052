.switch {
    position: relative;
    display: inline-block;
    width: 198px;
    height: 35px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    background-color: white;
}

.slider:before {
    position: absolute;
    content: "Night";
    height: 20px;
    width: 20px;
    left:35px;
    background-color: #F2F4F8;
    color: black;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    font-family: sans-serif;
    font-size: 20px;
    -webkit-transition: .4s;
    transition: .25s;
}

input:checked + .slider {
    background-color: #272A2D;
    color: white;
    width: 50%;
}

input:focus + .slider {
    box-shadow: 0 0 1px #929292;
}

input:checked + .slider:before {
    background-color: #17191D;
    -webkit-transform: translateX(99px);
    -ms-transform: translateX(99px);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    font-family: sans-serif;
    transform: translateX(99px);
    left:45px;
    content: "Day";
    border-radius: 0 5px 5px 0;
    font-size: 20px;
    color: white;
}

/* Rounded sliders */
.slider.round {
    width: 100%;
    height: 100%;
}

.slider.round:before {
    width: 50%;
    height: 100%;
}
.labels {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 20px;
    font-family: sans-serif;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
  }

 .labels::after {
    content: attr(data-night);
    position: absolute;
    right: 22px;
    top: 1px;
    color: black;
    opacity: 1;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    transition: all 0.4s ease-in-out;
  }

 .labels::before {
        content: attr(data-day);
        position: absolute;
        left: 35px;
        top:1px;
        color: white;
        opacity: 0;
        text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
        transition: all 0.4s ease-in-out;
      }
  
      input:checked~.labels::after {
        opacity: 0;
        transform: translateX(calc(var(--width) - var(--height)));
      }
  
     input:checked~.labels::before {
        opacity: 1;
        transform: translateX(calc(var(--width) - var(--height)));
      }
      .imageButton:focus{
        background-color: #F2F4F8;
      }